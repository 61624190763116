div.orgChart {
    border                : 1px solid #cccccc;
    /*background-color      : #ffffe8;*/
    margin                : 10px;
    padding               : 20px;
}

div.orgChart h2 {
    margin                : 0px;
    font-size             : 1em;
    border-bottom         : none;
}

div.orgChart ul {
    list-style            : none;
    margin                : 4px;
    padding               : 0px;
    font-size             : 0.8em;
    text-align            : left;
}

div.orgChart ul.stack,
div.orgChart ul.stack ul {
    text-align            : center;
}

div.orgChart table {
    width                 : 100%;
}

div.orgChart tr.lines td.line {
    width                 : 1px;
    height                : 20px;
}

div.orgChart tr.lines td.top {
    border-top            : 2px solid black;
}

div.orgChart tr.lines td.left {
    border-right          : 2px solid black;
    width                 : 25%;
}

div.orgChart tr.lines td.right {
    border-left           : 2px solid black;
    width                 : 25%;
}

div.orgChart td {
    text-align            : center;
    vertical-align        : top;
    padding               : 0px 2px;
}

div.orgChart div.node {
    cursor                : default;
    border                : 2px solid black;
    display               : inline-block;
    padding               : 0 4px;
    /*width                 : 96px;*/
    white-space: nowrap;
    height                : 60px;
    background-color      : #ffffcf;
    line-height           : 1.3em;
    border-radius         : 8px;
    box-shadow            : 4px 4px 6px #888888;
    -moz-border-radius    : 8px;
    -moz-box-shadow       : 4px 4px 6px #888888;
    -webkit-border-radius : 8px;
    -webkit-box-shadow    : 4px 4px 6px #888888;
}

div.orgChart div.hasChildren {
    background-color      : #ffff98;
}

div.orgChart.interactive div.hasChildren {
    cursor                : pointer;
}

div.orgChart div.node.hover {
    /*background-color      : white;*/
}

div.orgChart div.adjunct.node {
    position              : absolute;
    margin-top            : 8px;
    margin-left           : -110px;
    width                 : 80px;
    height                : 40px;
    background-color      : #efefef;
}

div.orgChart div.adjunct-link {
    display               : inline-block;
    position              : absolute;
    margin-left           : -20px;
    margin-top            : 25px;
    width                 : 20px;
    border                : 1px dashed black;
}

/* adicionado alteracoes */

div.orgChart div.node{
    height: 40px;;
}

div.orgChart h2{
    margin-left: 8px;
    margin-right: 8px;
    display: inline-flex;
}

div.orgChart h2 span, div.orgChart h2 a{
    margin-top: 13px;
}

div.orgChart{
    /*background: #ffffff;*/
    border: none;
}

div.orgChart div.node{
    border: none;
    background: #FDFDFD;
}