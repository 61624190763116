/*HAS ANY THEME */
#DashGridComponent:not(.DashboardTheme-NONE) .select-item-for-container .grid-stack-item-content,
#DashGridComponent:not(.DashboardTheme-NONE) .select-item-for-container .widget-box,
#DashGridComponent:not(.DashboardTheme-NONE) .select-item-for-container .widget-body,
#DashGridComponent:not(.DashboardTheme-NONE) .grid-stack-item.Container .item-content-container,
#DashGridComponent:not(.DashboardTheme-NONE) .grid-stack-item.Container .item-content-container .infobox,
#DashGridComponent:not(.DashboardTheme-NONE) .grid-stack-item.Container .jqplot-series-shadowCanvas {
    background-color: transparent;
}

#DashGridComponent:not(.DashboardTheme-NONE) .DashGridItem .widget-box .widget-body {
    transition: border-color ease-in-out 0.2s;
    border-color: transparent;
}

#DashGridComponent:not(.DashboardTheme-NONE) .widget-body,
#DashGridComponent:not(.DashboardTheme-NONE) .item-content-container .infobox,
#DashGridComponent:not(.DashboardTheme-NONE) .grid-stack > :not(.ui-resizable-disabled) > .grid-stack-item-content {
    background-color: rgba(0, 0, 0, 0);
}

#DashGridComponent:not(.DashboardTheme-NONE) .react-grid-item.grid-stack-item-content.ContainerChild.Item [id^=dashbox-] {
    background-color: rgba(255, 255, 255, 0) !important;
}

#DashGridComponent:not(.DashboardTheme-NONE) .widget-box {
    box-shadow: none;
}

#DashGridComponent:not(.DashboardTheme-NONE) .DashGrid .DashGridItem.Container,
#DashGridComponent:not(.DashboardTheme-NONE) .grid-stack .grid-stack-item.Container {
    padding: 0 !important;
    margin: 0 !important;
    border: 0;
}

#DashGridComponent:not(.DashboardTheme-NONE) .item-content-container.MapRender {
    overflow: hidden !important;
}

#DashGridComponent:not(.DashboardTheme-NONE) .ContainerRGL [id^=dashbox-] {
    margin: 0;
}

#DashGridComponent:not(.DashboardTheme-NONE) .widget-body {
    border: 0;
}

#DashGridComponent:not(.DashboardTheme-NONE) .dashboarditem .widget-header h4 div i {
    margin-right: 8px;
}

#DashGridComponent:not(.DashboardTheme-NONE) .grid-stack-item .widget-box .widget-header {
    display: none;
}

#DashGridComponent:not(.DashboardTheme-NONE) .DashGrid .DashGridItem.react-resizable {
    border-color: transparent;
}

.grid-stack-item .widget-box .widget-header {
    background-color: transparent;
    border: 0;
}

.on-mobile #body-wrapper #DashGridComponent:not(.DashboardTheme-NONE) {
    margin: 6px;
}

.on-mobile #DashGridComponent:not(.DashboardTheme-NONE).item-content-container.MapRender {
    overflow: hidden !important;
}

.on-mobile #DashGridComponent:not(.DashboardTheme-NONE) .map-legend.closed {
    max-height: 25px;
}

.on-mobile #DashGridComponent:not(.DashboardTheme-NONE) .legendMapTotal {
    display: none;
}

.on-mobile #DashGridComponent:not(.DashboardTheme-NONE) .map-legend.closed {
    position: absolute;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.7);
}

.on-mobile div#body-dashboard-home #DashGridComponent:not(.DashboardTheme-NONE) {
    width: 100%;
}

#DashGridComponent:not(.DashboardTheme-NONE) .KpiGrowth .kpi-comp-val-container > span {
    background-color: rgba(0, 0, 0, 0) !important;
}

/*Allow Border Radius*/
#DashGridComponent.AllowBorderRadius:not(.DashboardTheme-NONE) [id^=dashbox-],
#DashGridComponent.AllowBorderRadius:not(.DashboardTheme-NONE) .item-content-container,
#DashGridComponent.AllowBorderRadius:not(.DashboardTheme-NONE) .DashGrid .DashGridItem,
#DashGridComponent.AllowBorderRadius:not(.DashboardTheme-NONE) .DashGrid .DashGridItem.Container .grid-stack-item-content,
#DashGridComponent.AllowBorderRadius:not(.DashboardTheme-NONE) .grid-stack > :not(.ui-resizable-disabled) > .grid-stack-item-content {
    border-radius: 6px;
}

#DashGridComponent:not(.DashboardTheme-NONE):not(.AllowBorderRadius) .DashGridItem.Container .grid-stack-item-content {
    border-radius: 0;
}

/*Allow Margin*/
#DashGridComponent.AllowMargin:not(.DashboardTheme-NONE) .DashGridItem .grid-stack-item-content:not(.ContainerChild),
#DashGridComponent.AllowMargin:not(.DashboardTheme-NONE) .DashGridItem.Container {
    margin: var(--dashboard-margin, 6px);
}

#DashGridComponent.AllowContainerMargin:not(.DashboardTheme-NONE) .DashGridItem .grid-stack-item-content.ContainerChild {
    margin: var(--dashboard-container-margin, 6px);
}

#DashGridComponent.AllowBorderRadius:not(.DashboardTheme-NONE) .grid-stack-item .itemsize {
    border-radius: 0 6px 0 6px;
}

#DashGridComponent.AllowMargin:not(.DashboardTheme-NONE) #body-wrapper {
    margin: var(--dashboard-total-margin, 12px);
}

#DashGridComponent.AllowMargin:not(.DashboardTheme-NONE) .grid-stack-item-content-newmap .menu-mobile {
    top: 14px;
    right: 25px;
}

#DashGridComponent.AllowMargin:not(.DashboardTheme-NONE) .grid-stack-item-content-newmap.ContainerChild .dash-item-newmenu {
    top: 10px;
    right: 74px;
}

#DashGridComponent.AllowMargin:not(.DashboardTheme-NONE) .grid-stack-item-content-newmap.ContainerChild .menu-mobile {
    top: 1px;
    right: 30px;
}

#DashGridComponent.AllowMargin:not(.DashboardTheme-NONE) .DashGridItem:not(.Container) [id^=dashbox-] .item-content-container > div {
    overflow: hidden;
}

#DashGridComponent.AllowMargin:not(.DashboardTheme-NONE) .DashGridItem:not(.Container) [id^=dashbox-] .item-content-container[class*=Analysis] > div,
#DashGridComponent.AllowMargin:not(.DashboardTheme-NONE) .DashGridItem:not(.Container) .item-content-image > div,
#DashGridComponent.AllowMargin:not(.DashboardTheme-NONE) .DashGridItem:not(.Container) [id^=dashbox-] .item-content-container[class*=Map] > div,
#DashGridComponent.AllowMargin:not(.DashboardTheme-NONE) .DashGridItem:not(.Container) [id^=dashbox-] .item-content-container[class*=Orgmap] > div {
    margin: 12px;
}

#DashGridComponent.AllowMargin:not(.DashboardTheme-NONE) .DashGridItem:not(.Container) [id^=dashbox-] .item-content-container > div [class^=div-table-],
#DashGridComponent.AllowMargin:not(.DashboardTheme-NONE) .DashGridItem:not(.Container) [id^=dashbox-] .item-content-container > div [class^=div-table-] {
    overflow: auto;
}

/*Allow BoxShadow*/
#DashGridComponent.AllowBoxShadow:not(.DashboardTheme-NONE) .DashGridItem:not(.Container) .grid-stack-item-content,
#DashGridComponent.AllowBoxShadow:not(.DashboardTheme-NONE) .DashGridItem.Container > div {
    box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.14);
}

/*END HAS ANY THEME*/


/*WHITE theme*/
.DashboardTheme-WHITE div[class*="breakpoint-MOBILE"] .DashGrid.grid-stack {
    background-color: #f5f5f5 !important;
}

.background-preview-WHITE {
    background-color: #f5f5f5;
}

/*BLACK theme*/
.DashboardTheme-BLACK .react-grid-item.DashGridItem.grid-stack-item.Container,
.DashboardTheme-BLACK .grid-stack-item.Container .item-content-container .infobox {
    border: 0;
}

.DashboardTheme-BLACK table.mdx-table th,
.DashboardTheme-BLACK table.mdx-table td:not([style*="background"]):not([class*="green"]):not([class*="yellow"]):not([class*="red"]) {
    filter: invert(90%) hue-rotate(180deg);
}
.DashboardTheme-BLACK table.mdx-table th,
.DashboardTheme-BLACK table.mdx-table td {
    border: solid 1px #000000;
}
.DashboardTheme-BLACK .table-theme-clean .mdx-table tr:nth-child(even),
.DashboardTheme-BLACK .table-theme-clean .mdx-table tr:nth-child(even) th,
.DashboardTheme-BLACK .table-theme-clean .mdx-table tr:nth-child(even) td {
    background: #ffffff;
}
.DashboardTheme-BLACK .table-theme-clean .mdx-table {
    background: #242424;
}
.DashboardTheme-BLACK .table-theme-silver .mdx-table tbody tr:nth-child(odd),
.DashboardTheme-BLACK .table-theme-silver .mdx-table tbody tr:nth-child(odd) th,
.DashboardTheme-BLACK .table-theme-silver .mdx-table tbody tr:nth-child(odd) td {
    border-top: solid 1px #000000;
    border-right: solid 1px #000000;
}
.DashboardTheme-BLACK .table-theme-silver .mdx-table thead tr th,
.DashboardTheme-BLACK .table-theme-silver .mdx-table thead tr td,
.DashboardTheme-BLACK .table-theme-silver .mdx-table thead tr,
.DashboardTheme-BLACK .table-theme-silver .mdx-table thead {
    border-top: solid 1px #000000;
    border-right: solid 1px #000000;
}
.DashboardTheme-BLACK .table-theme-silver .mdx-table tbody tr:nth-child(even),
.DashboardTheme-BLACK .table-theme-silver .mdx-table tbody tr:nth-child(even) th,
.DashboardTheme-BLACK .table-theme-silver .mdx-table tbody tr:nth-child(even) td {
    border-top: solid 1px #000000;
    border-right: solid 1px #000000;
}
.DashboardTheme-BLACK .container-dropdown-menu.with-title,
.DashboardTheme-BLACK .container-dropdown-menu.with-title *,
.DashboardTheme-BLACK .container-dropdown-menu.blank-icon-and-title *,
.DashboardTheme-BLACK .container-dropdown-menu.with-title .Icon {
    color: #ffffff;
}

.DashboardTheme-BLACK .container-dropdown-menu.with-title .icon-dropdown:hover,
.DashboardTheme-CORPORATIVE .container-dropdown-menu.with-title .icon-dropdown:hover {
    background: transparent !important;
}

.DashboardTheme-BLACK .container-dropdown-menu .DashboardItemMenuDropdown {
    color: #222;
}

.DashboardTheme-BLACK .titleItemDash div,
.DashboardTheme-BLACK div[class*='imgChart'] div {
    color: #ececec !important;
}

.DashboardTheme-BLACK .kpititle,
.DashboardTheme-BLACK .KpiTarget,
.DashboardTheme-BLACK .KpiInternalBody div[id*=percent],
.DashboardTheme-BLACK .KpiDescriptionSpan,
.DashboardTheme-BLACK .icon-chevron-left,
.DashboardTheme-BLACK .KpiBandsValue,
.DashboardTheme-BLACK .kpi-percent,
.DashboardTheme-BLACK .kpidescription,
.DashboardTheme-BLACK .KpiGrowth {
    color: #ececec;
}

.DashboardTheme-BLACK .pie-holder div {
    color: #ececec !important;
}

.DashboardTheme-BLACK .infobox,
.DashboardTheme-BLACK .grid-stack-item .itemsize {
    color: #ffffff;
}

.DashboardTheme-BLACK div[class*="breakpoint-MOBILE"] .DashGrid.grid-stack {
    background-color: #222222 !important;
}

.background-preview-BLACK {
    background-color: #191919;
}

.DashboardTheme-BLACK .grid-stack-item .itemsize {
    background-color: #000;
}

/*CORPORATIVE theme*/
.DashboardTheme-CORPORATIVE .react-grid-item.DashGridItem.grid-stack-item.Container,
.DashboardTheme-CORPORATIVE .grid-stack-item.Container .item-content-container .infobox {
    border: 0;
}

.DashboardTheme-CORPORATIVE div.orgChart tr.lines td.line {
    border-color: #fff;
}

.DashboardTheme-CORPORATIVE .container-dropdown-menu.with-title {
    box-shadow: inset 0 -1px 0 0 #aaa;
}

.DashboardTheme-CORPORATIVE .container-dropdown-menu.with-title,
.DashboardTheme-CORPORATIVE .container-dropdown-menu.with-title *,
.DashboardTheme-CORPORATIVE .container-dropdown-menu.blank-icon-and-title *{
    color: #ffffff;
}

.DashboardTheme-CORPORATIVE .leaflet-tile-pane,
.DashboardTheme-BLACK .leaflet-tile-pane {
    filter: invert(1) grayscale(1);
}

.DashboardTheme-CORPORATIVE .container-dropdown-menu .DashboardItemMenuDropdown {
    color: #222;
}

.DashboardTheme-CORPORATIVE .titleItemDash div,
.DashboardTheme-CORPORATIVE div[class*='imgChart'] div {
    color: #ececec !important;
}

.DashboardTheme-CORPORATIVE .kpititle,
.DashboardTheme-CORPORATIVE .KpiTarget,
.DashboardTheme-CORPORATIVE .KpiInternalBody div[id*=percent],
.DashboardTheme-CORPORATIVE .KpiDescriptionSpan,
.DashboardTheme-CORPORATIVE .icon-chevron-left,
.DashboardTheme-CORPORATIVE .KpiBandsValue,
.DashboardTheme-CORPORATIVE .kpi-percent,
.DashboardTheme-CORPORATIVE .kpidescription,
.DashboardTheme-CORPORATIVE .KpiGrowth {
    color: #ececec;
}

.DashboardTheme-CORPORATIVE .pie-holder div {
    color: #ececec !important;
}

.DashboardTheme-CORPORATIVE .grid-stack-item .itemsize {
    color: #ffffff;
    background-color: #000;
}

.DashboardTheme-CORPORATIVE .infobox {
    color: #ffffff;
}

.DashboardTheme-CORPORATIVE div[class*="breakpoint-MOBILE"] .DashGrid.grid-stack {
    background-color: #251841 !important;
}

.DashboardTheme-CORPORATIVE .table-theme-clean tr.col-total-row {
    background-color: #FFFFFF !important;
}

.background-preview-CORPORATIVE {
    background-color: #251841;
}

/*HIGHLIGHT ITEM*/
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff div.orgChart tr.lines td.line,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 div.orgChart tr.lines td.line {
    border-color: #000;
}

#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .kpititle,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .kpititle,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .KpiTarget,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .KpiTarget,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .kpi-percent,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .kpi-percent,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .KpiInternalBody div[id*=percent],
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .KpiInternalBody div[id*=percent],
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .KpiDescriptionSpan,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .KpiDescriptionSpan,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .kpidescription,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .kpidescription,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .icon-chevron-left,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .icon-chevron-left,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .KpiBandsValue,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .KpiBandsValue,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .KpiGrowth,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .KpiGrowth,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .ag-paging-panel.ag-unselectable,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .ag-paging-panel.ag-unselectable {
    color: #333333 !important;
}

#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .container-dropdown-menu .BngIconButton,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .dash-item-newmenu .MenuButtonsContainer,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .container-dropdown-menu .BngIconButton,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .dash-item-newmenu .MenuButtonsContainer,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton {
    background-color: #FFFFFF !important;
    color: #333333 !important;
    opacity: 0.85;
}

#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .container-dropdown-menu .BngIconButton:hover,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton:hover,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton:hover,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .container-dropdown-menu .BngIconButton:hover,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton:hover,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton:hover {
    background-color: #E4E4E4 !important;
    opacity: 0.85;
    color: #333333 !important;
}

#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .container-dropdown-menu .BngIconButton:focus,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton:focus,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton:focus,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .container-dropdown-menu .BngIconButton:focus,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton:focus,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton:focus {
    background-color: #C3C3C3 !important;
    opacity: 0.85;
    color: #333333 !important;
}

#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-6925e8 .container-dropdown-menu .BngIconButton,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-6925e8 .dash-item-newmenu .MenuButtonsContainer,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-6925e8 .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-6925e8 .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-528de3 .container-dropdown-menu .BngIconButton,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-528de3 .dash-item-newmenu .MenuButtonsContainer,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-528de3 .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-528de3 .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-269c59 .container-dropdown-menu .BngIconButton,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-269c59 .dash-item-newmenu .MenuButtonsContainer,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-269c59 .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-269c59 .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-eb354d .container-dropdown-menu .BngIconButton,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-eb354d .dash-item-newmenu .MenuButtonsContainer,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-eb354d .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-eb354d .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton {
    color: #FFFFFF !important;
    background-color: #333333 !important;
    opacity: 0.85;
}

#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-6925e8 .container-dropdown-menu .BngIconButton:hover,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-6925e8 .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton:hover,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-6925e8 .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton:hover,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-528de3 .container-dropdown-menu .BngIconButton:hover,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-528de3 .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton:hover,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-528de3 .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton:hover,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-269c59 .container-dropdown-menu .BngIconButton:hover,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-269c59 .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton:hover,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-269c59 .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton:hover,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-eb354d .container-dropdown-menu .BngIconButton:hover,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-eb354d .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton:hover,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-eb354d .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton:hover {
    background-color: #444444 !important;
    opacity: 0.85;
    border: none !important;
}

#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-6925e8 .container-dropdown-menu .BngIconButton:focus,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-6925e8 .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton:focus,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-6925e8 .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton:focus,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-528de3 .container-dropdown-menu .BngIconButton:focus,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-528de3 .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton:focus,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-528de3 .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton:focus,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-269c59 .container-dropdown-menu .BngIconButton:focus,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-269c59 .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton:focus,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-269c59 .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton:focus,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-eb354d .container-dropdown-menu .BngIconButton:focus,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-eb354d .dash-item-newmenu .MenuButtonsContainer .DashboardObjectOptsButton:focus,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-eb354d .dash-item-newmenu .MenuButtonsContainer .DrillButtons .BngIconButton:focus {
    background-color: #555555 !important;
    opacity: 0.85;
}

#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight .kpititle,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight .KpiTarget,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight .kpi-percent,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight .KpiInternalBody div[id*=percent],
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight .KpiDescriptionSpan,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight .kpidescription,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight .icon-chevron-left,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight .KpiBandsValue,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight .KpiGrowth,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight .ag-paging-panel.ag-unselectable{
    color: #ffffff;

}

#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-6925e8 .container-dropdown-menu.with-title,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-6925e8 .container-dropdown-menu.with-title *,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-528de3 .container-dropdown-menu.with-title,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-528de3 .container-dropdown-menu.with-title *,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-269c59 .container-dropdown-menu.with-title,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-269c59 .container-dropdown-menu.with-title *,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-eb354d .container-dropdown-menu.with-title,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-eb354d .container-dropdown-menu.with-title * {
    color: #ffffff !important;
}

#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .container-dropdown-menu.with-title,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-ffffff .container-dropdown-menu.with-title *,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .container-dropdown-menu.with-title,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight.color-highlight-fec447 .container-dropdown-menu.with-title * {
    color: #333333 !important;
}

#DashGridComponent:not(.DashboardTheme-NONE) div.item-highlight.color-highlight-ffffff .titleItemDash div,
#DashGridComponent:not(.DashboardTheme-NONE) div.item-highlight.color-highlight-fec447 .titleItemDash div,
#DashGridComponent:not(.DashboardTheme-NONE) div.item-highlight.color-highlight-ffffff div[class*='imgChart'] div,
#DashGridComponent:not(.DashboardTheme-NONE) div.item-highlight.color-highlight-fec447 div[class*='imgChart'] div,
#DashGridComponent:not(.DashboardTheme-NONE) div.item-highlight.color-highlight-ffffff .itemsize,
#DashGridComponent:not(.DashboardTheme-NONE) div.item-highlight.color-highlight-fec447 .itemsize {
    color: #333333 !important;
}

#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight:not(.color-highlight-ffffff) .titleItemDash div,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight:not(.color-highlight-fec447) .titleItemDash div,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight:not(.color-highlight-ffffff) div[class*='imgChart'] div,
#DashGridComponent:not(.DashboardTheme-NONE) .item-highlight:not(.color-highlight-fec447) div[class*='imgChart'] div {
    color: #FFFFFF !important;
}

/**/

.DashboardTheme-WHITE div[class*="breakpoint-MOBILE"] .DashGrid.grid-stack {
    background-color: #f5f5f5 !important;
}