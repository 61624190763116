.dashboard-responsive-text .TextLabel {
  line-height: 1em;
}

.dashboard-responsive-text {
  overflow: auto;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1em;
}

.dashboard-responsive-text .inline-block {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.dashboard-responsive-text.PositionLEFT .inline-block {
  flex-direction: row-reverse;
}

.dashboard-responsive-text.PositionTOP .inline-block {
  flex-direction: column-reverse;
}

.dashboard-responsive-text.PositionBOTTOM .inline-block {
  flex-direction: column;
}

.dashboard-responsive-text.Alignleft .inline-block {
  justify-content: flex-start;
}

.dashboard-responsive-text.Alignright .inline-block {
  justify-content: flex-end;
}

.dashboard-label-value {
  word-break: break-all;
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 500;
  width: 100%;
  line-height: 150%;
}

.text-label-icon {
  font-size: 15px;
}

.form-menu-left {
  float: left;
  margin-top: 14%;
  margin-right: 4px;
  word-wrap: break-word !important;
  max-width: 130px;
}

.form-menu-right {
  float: right;
  margin-top: 14%;
  word-wrap: break-word !important;
  max-width: 130px;
}

.form-menu {
  position: absolute;
  top: 60.5%;
  left: 67%;
  right: 9%;
  word-wrap: break-word !important;
  max-width: 125px;
}

.label-form {
  word-wrap: normal !important;
}

.dashboard-responsive-text .material-icons {
  font-size: unset;
}

.DashGridItem.highlight .dashboard-responsive-text {
  cursor: pointer;
}

.dashboard-responsive-text .TextLabel {
  word-break: break-word;
}

.TextValue {
  max-width: 100%;
}

.dashboard-responsive-text-container .loading-image-box {
  padding: 0 !important;
}

.dashboard-responsive-text-container .spinner {
  width: 50% !important;
  max-width: 100px;
  height: 50% !important;
  max-height: 100px;
}

.dashboard-responsive-text img {
  border-radius: 4px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
