.map-query-param-panel {
    padding: 10px;
}

.map-query-param-panel > .row-fluid:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
}

.map-query-param-panel > .row-fluid {
    margin-top: 10px;
    padding-top: 5px;
    border-top: 1px solid #dddddd;
}

.map-parameter-label-format {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.map-parameter-label-format-icon {
    color: #669FC7;
    cursor: hand;
    right: 0;
    position: absolute;
    top: 0;
}

.map-filter .filter-container {
    margin-left: 0;
}

/*botoes do maps*/
li.tab-buttons span.button-container button.btn.btn-mini.btn-table {
    margin-top: 5px;
}