.bng-snackbar {
    padding: 0;
    width: 100%;
    max-width: fit-content;
    border-radius: 100px;
    margin: auto;
    text-align: center;
    display: flex;
    gap: 2px;
    position: sticky;
    z-index: 2;
}

.bng-snackbar span,
.bng-snackbar .Icon {
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    vertical-align: middle;
    display: table-cell;
}

.bng-snackbar .material-icons {
    font-size: 21px;
}

.bng-snackbar .bng-snackbar-icon {
    padding-right: 0;
    margin-right: 10px;
}

.bng-snackbar .bng-snackbar-message {
    padding-left: 5px;
}

.bng-snackbar.no-data {
    cursor: default;
    background-color: #6c6c6c;
    max-width: 260px;
}

.bng-snackbar.error {
    cursor: pointer;
}

.bng-snackbar.warning {
    cursor: pointer;
    background-color: #f89406;
}

.bng-snackbar.not-loaded {
    cursor: default !important;
    background-color: #337dff;
    max-width: 260px;
    padding-left: 15px;
}

.bng-snackbar.not-loaded .Icon {
    animation: rotation 3s linear 0s infinite normal none;
    height: 31px;
    padding: 0;
}

@keyframes rotation {
    0.0% {
    }
    100% {
        transform: rotate(-360deg);
    }
}

.snackbarContainer {
    padding: 10px;
}

.snackbarContainer,
.snackbarChildrenContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: 20px;
}

.snackbarContainer.withChildren {
    border-radius: 20px 0 0 20px;
}

.snackbarChildrenContainer {
    min-width: 35px;
    cursor: pointer;
    border-radius: 0 20px 20px 0;
}

.snackbarVariantSave {
    background-color: #3558ef;
}

.snackbarVariantError {
    background-color: #e23d3d;
}

.snackbarVariantUnloaded {
    background-color: #6C6C6C;
}