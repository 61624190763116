.DashGrid.grid-stack > :not(.ui-resizable-disabled) > .grid-stack-item-content {
    padding: 0;
}

.DashGrid .DashGridItem.grid-stack-item {
    min-width: unset;
    -webkit-overflow-scrolling: touch;
}

.DashGrid .item-menu-trigger {
    display: none;
}

.DashGrid .DashGridItem.react-resizable {
    border: 1px solid #ccc;
}

.react-grid-placeholder {
    background-color: #aaaaaa !important;
}

div[class*="breakpoint-MOBILE mobile-frame"] {
    border-image: linear-gradient(to right, #e5e5e5 318px, rgba(255, 255, 255, 0) 318px);
    border-image-slice: 1;
    overflow: hidden;
}

.breakpoint-MOBILE {
    height: 100%;
    border-top: 2px solid;
    border-bottom: 2px solid;
    margin: -1px 6px;
}

.breakpoint-MOBILE_HORIZONTAL.mobile-frame {
    position: relative;
    height: 337px;
    overflow-y: scroll !important;
}

div[class*="breakpoint-MOBILE mobile-frame"]:hover {
    overflow-y: auto;
}

div[class*="breakpoint-MOBILE mobile-frame"]::-webkit-scrollbar {
    visibility: hidden;
    width: 5px;
}

div[class*="breakpoint-MOBILE mobile-frame"]::-webkit-scrollbar-thumb {
    background: #949494;
    border-radius: 10px;
}

div[class*="breakpoint-MOBILE mobile-frame"]::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

div[class*="div-MOBILE"] {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.div-MOBILE {
    margin: auto;
    padding-bottom: 40px;
    padding-top: 60px;
    height: 610px;
}

/* On Mobile View */
.AllContentWrapper .div-MOBILE {
    padding-top: 0;
    padding-bottom: 0;
    height: auto;
}
.AllContentWrapper.on-ios .div-MOBILE {
    height: calc(100vh - 35px);
}

.AllContentWrapper.on-mobile {
    height: 100vh;
}

.AllContentWrapper.on-mobile .render-container-panel.scroll-content.scroll-scrolly_visible,
.AllContentWrapper.on-mobile .scroll-wrapper.render-container-panel {
    height: 101vh !important;
}

.AllContentWrapper.on-ios .dashboard-wrapper-with-filters{
    padding-top: 0 !important;
}

.AllContentWrapper #dashboard-wrapper {
    width: 100% !important;
    height: 100%;
}

.AllContentWrapper .breakpoint-MOBILE {
    height: 100%;
    border: 0;
    margin: 0;
}

.AllContentWrapper #body-dashboard-home {
    overflow-y: visible !important;
    overflow-x: clip !important;
    width: 100%;
}
.AllContentWrapper #body-dashboard-home.free-style-marker-class.no-filter-bar {
    height: 100vh;
}

.AllContentWrapper #DashGridComponent {
    margin: 0;
}

.on-mobile #body-wrapper #DashGridComponent:not(.DashboardTheme-NONE) {
    margin: 0;
}

#dashboard-wrapper #body-wrapper {
    margin: 0 !important;
    height: 100%;
}

.div-MOBILE_HORIZONTAL.mobile-frame {
    margin-top: 35px;
    margin-left: auto;
    margin-right: calc(50% - 250px);
    height: 337px;
    padding: 11px 40px 11px 60px;
}

.AllContentWrapper .div-MOBILE_HORIZONTAL {
    height: auto;
}
.AllContentWrapper.on-ios .div-MOBILE_HORIZONTAL {
    height: calc(100vh - 35px);
}

.beta-style-dashgrid {
    margin-right: 10px !important;
    margin-top: -18px !important;
}

.DashGridItem .overlay,
.Table-overlay .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #EEE;
    opacity: 0.35;
    color: #333;
}

.DashGridItem .overlay-container i.fa,
.Table-overlay .overlay-container i.fa {
    position: absolute;
    top: 5px;
    right: 5px;
}

/* Blocks body x scroll while dragging. See: https://github.com/sol7/bi-machine/issues/1234 */
body.DashGridDraggingItem {
    overflow-x: hidden;
}

.alertConfigDashMobile {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #FF1A4C;
    opacity: 0.9;
    width: calc(100vw - 24px);
    padding: 12px;
    display: flex;
    align-items: center;
}

.paddingConfigDashMobile {
    text-align: center;
    margin: auto;
    color: #fff;
}

.spamAlertConfigDashMobile {
    font-size: 13px;
    line-height: 15px;
    text-align: center;
}

.spamAlertConfigDashMobilelink {
    margin-left: 5px;
    color: #FFFFFF;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    text-decoration: underline !important;
}

.timesAlertConfigDashMobile {
    font-size: 17px;
    color: #fff;
    padding: 0 5px;
}

.DashGrid .react-grid-layout,
.DashGrid .react-grid-item {
    transition: none;
}

.DashGrid.inEditMode .map-loader {
    z-index: 0;
}

.DashGrid .DashGridItem.Container,
.grid-stack .grid-stack-item.Container {
    /*box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.14);*/
    border-radius: 4px;
    padding: 2px 0;
    border: 1px solid #efefef;
}

.DashGrid .DashGridItem.Container .grid-stack-item-content {
    border-radius: 3px;
}

.grid-stack-item.Container .item-content-container,
.grid-stack-item.Container .item-content-container .infobox,
.grid-stack-item.Container .jqplot-series-shadowCanvas {
    background: #fff;
}

.DashGrid .DashGridItem .itemsize.onLeft {
    left: 0;
    bottom: 0;
    right: unset;
}

.DashGrid .DashGridItem.Container .dashboard-item {
    box-shadow: none;
}

/*select item on dashgrid*/
.DashGrid .DashGridItem.selectedToClone {
    box-shadow: 0 0 0 1px #005dff;
    z-index: 1;
}

.DashGrid .DashGridItem.selectedToClone,
.DashGrid .DashGridItem.selectedToClone .widget-box .widget-body {
    border-color: transparent;
}

.DashGrid .selectedToClone .react-resizable-handle,
.DashGrid .selectedToClone .CustomResizeHandle,
.DashGrid .selected-by-container .react-resizable-handle,
.DashGrid .selected-by-container .CustomResizeHandle {
    visibility: visible;
}

.DashGrid .DashGridItem .ContainerChild .CustomResizeHandle,
.DashGrid .DashGridItem .ContainerChild .react-resizable-handle {
    visibility: collapse;
}

.DashGrid .react-resizable-handle {
    visibility: collapse;
    bottom: -7px;
    right: -7px;
    opacity: 0;
}

.DashGrid .CustomResizeHandle {
    visibility: collapse;
    width: 14px;
    height: 14px;
    position: absolute;
    bottom: -7px;
    right: -7px;
    cursor: se-resize;
}

.DashGrid .DashGridItem {
    transition: box-shadow ease-in 0.2s;
}

.DashGrid .DashGridItem .widget-box .widget-body {
    transition: border-color ease-in-out 0.2s;
}

/*ITEM ACTION ON HOVER*/
.DashGrid .DashGridItem:not(.Container) > .grid-stack-item-content .HoverHighlight:not(.DISABLE_CLICK),
.DashGrid .ContainerChild.grid-stack-item-content .HoverHighlight:not(.DISABLE_CLICK) {
    opacity: 1;
    transition: opacity 0.25s linear;
}

.DashGrid .DashGridItem:not(.Container) > .grid-stack-item-content:hover .HoverHighlight:not(.DISABLE_CLICK),
.DashGrid .ContainerChild.grid-stack-item-content:hover .HoverHighlight:not(.DISABLE_CLICK) {
    opacity: 0.7;
    cursor: pointer;
}

.OnContainerCreation .DashGrid .DashGridItem:not(.Container) > .grid-stack-item-content:hover .HoverHighlight:not(.DISABLE_CLICK),
.OnGridCreation .DashGrid .DashGridItem:not(.Container) > .grid-stack-item-content:hover .HoverHighlight:not(.DISABLE_CLICK),
.OnContainerCreation .DashGrid .ContainerChild.grid-stack-item-content:hover .HoverHighlight:not(.DISABLE_CLICK),
.OnGridCreation .DashGrid .ContainerChild.grid-stack-item-content:hover .HoverHighlight:not(.DISABLE_CLICK) {
    opacity: 1;
    cursor: default;
}

/*Empty Dashboard*/
.BngEmpty.EmptyDashboard-MOBILE-DEVICE {
    position: relative;
    margin: calc(50vh - 220px) 0 !important;
    transform: none;
}

.BngEmpty.EmptyDashboard-DESKTOP {
    top: calc(50vh - 84px);
}

div[class*='EmptyDashboard'] .Container {
    width: 100%;
}

div[class*='EmptyDashboard'] .Image {
    height: 250px;
}

div[class*='EmptyDashboard'] .Message {
    font-weight: 400;
    font-size: 13px;
}

.BngEmpty.EmptyDashboard-DESKTOP .Title {
    padding: 35px 0 20px;
    font-size: 36px;
    line-height: 36px;
}

.BngEmpty.EmptyDashboard-DESKTOP .Message {
    letter-spacing: 0.8px;
    font-size: 22px;
    line-height: 40px;
}

div[class*='EmptyDashboard-MOBILE'] .Image {
    height: 210px;
}

div[class*='EmptyDashboard-MOBILE'] .Title {
    padding: 35px 0 15px;
    font-size: 27px;
    line-height: 27px;
}

div[class*='EmptyDashboard-MOBILE'] .Message {
    letter-spacing: 0.8px;
    font-size: 18px;
    line-height: 25px;
}

/*ajustes posicionamento botões do mapa discover*/
.leaflet-touch.mobile {
    top: 25px !important;
}

.leaflet-left.mobile {
    top: 15px !important;
}

#DashGridComponent .grid-stack-item-content-newmap .menu-mobile {
    top: 1px;
    right: 14px;
}

#DashGridComponent .grid-stack-item-content-newmap.ContainerChild .noData {
    top: 0;
    right: 25px;
}

.DashGrid .grid-stack-item-content-bigtable.DashTheme-black .ag-paging-panel.ag-unselectable,
.DashGrid .grid-stack-item-content-bigtable.DashTheme-corporative .ag-paging-panel.ag-unselectable,
.DashboardTheme-BLACK .ag-paging-panel,
.DashboardTheme-CORPORATIVE .ag-paging-panel {
    color: white;
}

.ImgTransparentBg.grid-stack-item-content.grid-stack-item-content-imageContent {
    background-color: rgba(0, 0, 0, 0) !important;
}

.OnContainerCreation .container-dropdown-menu {
    display: none;
}

.DashboardStructureNotLoadedPage {
    width: calc(100vw - 122px) !important;
}

.BngApp .DashGrid .map-loader {
    height: calc(100% - 24px);
}

.AllContentWrapper #filter-bar-container .filterItemsHolder {
    margin-left: 0;
}

.GridEl > .appleDevicesFix {
    position: sticky;
}