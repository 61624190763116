.item-content-container.MapRender .zoom-target-disable,
.item-content-container.MapRender .zoom-target {
    height: 100%;
}

.RenderablePreload {
    width: 100%;
    height: 100%;
}

.RenderablePreload-error {
    position: absolute;
    opacity: 0.8;
}

.RenderablePreload-error > .alert{
    padding-right: 14px;
    margin: 0;
}

.grid-stack-item .widget-box .widget-header,
.grid-stack-item .widget-box .widget-body {
    border-radius: 0;
}

.AnalysisChartRender .AnalysisChartRenderWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.AnalysisChartRender .AnalysisChartRenderWrapper .AnalysisChartRenderChart {
    flex-grow: 1;
}